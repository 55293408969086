import $ from "jquery";


function App() {


    const links = {
        Twitter: "https://x.com/basedjesuscoin",
        BASE: "",
        DEX: "",
        TG: "",
        Raydium: "",
        UNISWAP: "",
        CONTRACT: "0xff73d4342624e23580c99f5d3b591d0b"
    }

    const copytext = () => {
        const contractValue = links.CONTRACT;
        if (contractValue) {
            navigator.clipboard.writeText(`${contractValue}`)
                .then(() => {
                    console.log('Value copied to clipboard:', contractValue);
                    // You can add further actions here if needed
                })
                .catch(err => {
                    console.error('Failed to copy:', err);
                    // Handle the error if copying fails
                });
        }
    }

    const clicker = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
            $('.main-soc').removeClass('active');
            $('body').removeClass('lock');
        }
    }
    const burger = () => {
        if ($('#buger').hasClass('clicked')) {
            $('.header__burger').removeClass('clicked');
            $('.header__burger').removeClass('active');
            $('.mob-menu').removeClass('active');
            $('.header-menu').removeClass('active');
            $('.main-soc').removeClass('active');
            $('body').removeClass('lock');
        } else {
            $('.header__burger').addClass('clicked');
            $('.header__burger').addClass('active');
            $('.mob-menu').addClass('active');
            $('.header-menu').addClass('active');
            $('.main-soc').addClass('active');
            $('body').addClass('lock');
        }
    }


    return (
        <div className="App">
            <div class="jesus1">
                <div class="nav left-rigth">
                    <div class="menu">
                        <div class="logo">
                            <img src="/img/logo.png" alt="SJESUS" />
                        </div>
                        <div class="soc">
                            <div class="copy">
                                <p id="text-to-copy">{links.CONTRACT}</p>
                                <img src="/img/copy.svg" alt="copy" id="copy-button" onClick={() => copytext()} />
                            </div>
                            <div class="links">
                                <a href={links.DEX} target="_blank" rel="noreferrer">
                                    <img src="/img/dex.png" alt="DEX" />
                                </a>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">
                                    <img src="/img/tw.png" alt="X" />
                                </a>
                                <a href={links.TG} target="_blank" rel="noreferrer">
                                    <img src="/img/tg.png" alt="TG" />
                                </a>
                                <a href={links.BASE} target="_blank" rel="noreferrer">
                                    <img src="/img/base.png" alt="BASE" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="mob-menu">
                        <div className="mob-menu__items" onClick={() => clicker()}>
                            <div class="header-soc">
                                <a href={links.DEX} target="_blank" rel="noreferrer">
                                    <img src="/img/dex.png" alt="DEX" />
                                </a>
                                <a href={links.Twitter} target="_blank" rel="noreferrer">
                                    <img src="/img/tw.png" alt="X" />
                                </a>
                                <a href={links.TG} target="_blank" rel="noreferrer">
                                    <img src="/img/tg.png" alt="TG" />
                                </a>
                                <a href={links.BASE} target="_blank" rel="noreferrer">
                                    <img src="/img/base.png" alt="BASE" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="header__burger" id="buger" onClick={() => burger()}>
                        <span></span>
                    </div>
                </div>
                <p className='sol-text left-rigth'>
                    based <br /> jesus: DO GOOD &<br /> GIVE MORE
                </p>
            </div>
            <div class="jesus">
                <p>
                    $BESUS🙏
                </p>
            </div>
            <div class="jesus2 left-rigth">
                <div class="jesus2-img">
                    <img src="/img/jesus2.jpg" alt="JESUS" />
                </div>
                <div class="jesus2-text">
                    THE WAY, THE TRUTH,
                    AND THE LIFE. ❤️
                </div>
            </div>
            <div class="jesus3 left-rigth">
                <div class="jesus3-text">
                    THE MISSION OF <p>$bESUS</p> COIN IS TO INFLUENCE base blockchain CULTURE AND BE A LIGHT IN DARK PLACES. IT IS A DECENTRALIZED, FAITH-BASED CRYPTO THAT INSPIRES PEOPLE TO LOVE ONE ANOTHER AND SEEK A HIGHER CALLING.
                </div>
                <div class="jesus2-img">
                    <img src="/img/jesus3.jpg" alt="JESUS" />
                </div>
            </div>
            <div class="block">
                <p className='buy'>
                    How to buy🕊️
                </p>

                <div class="jesus5 jesus">
                    <a href={links.UNISWAP} target="_blank" rel="noreferrer">
                        buy on uniswap
                    </a>
                </div>
                <div class="jesus4 jesus">
                    <p>
                        $bESUS
                    </p>
                </div>
                <div class="jesus road">
                    <p>
                        $bESUS🙏
                    </p>
                    <p>
                        $bESUS🙏
                    </p>
                    <p>
                        $bESUS🙏
                    </p>
                </div>
                <div class="footer">
                    <p>
                        This fan project is not officially associated with @JESUSTOKENS It's a separate creation inspired by their work and made by independent team. $BESUS OPERATES ON THE BASE BLOCKCHAIN AS A MEME COIN. THIS INFORMATION DOES NOT CONSTITUTE INVESTMENT ADVICE. THE COIN IS INTENDED FOR ENTERTAINMENT AND EDUCATIONAL PURPOSES ONLY. THE FOUNDERS DISCLAIM LIABILITY FOR ANY LOSSES OR DAMAGES. THE CRYPTOCURRENCY MARKET IS HIGHLY VOLATILE; INVEST AT YOUR OWN RISK. THERE ARE NO GUARANTEES OF PROFIT OR VALUE RETENTION. PRIOR TO INVESTING, CONDUCT THOROUGH RESEARCH. BY PURCHASING, YOU ACKNOWLEDGE THE INHERENT RISKS. THE FOUNDERS HAVE NO OBLIGATION TO UPDATE INFORMATION. COMPLY WITH LOCAL REGULATIONS, AS LAWS VARY BY JURISDICTION. THIS DISCLAIMER IS SUBJECT TO CHANGES WITHOUT NOTICE.
                    </p>
                    <span>
                        2024 © BASED JESUS. All rights reserved.
                    </span>
                </div>
            </div>
        </div>
    );
}

export default App;
